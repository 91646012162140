import axios from "axios";
import { toast } from "react-toastify";
const getAuthToken = () => {
  const auth = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;
  if (auth) {
    return `Bearer ${auth}`;
  }
  return null;
};
const provider = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASEURL,
    "Allow-Origin": process.env.REACT_APP_BASEURL,
  },
});
provider.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,

      Authorization: getAuthToken(),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    provider
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/login";
        } else if (res?.response?.status === 500) {
          toast.error(
            "Oops something went wrong, please try again after sometime or contact Merago Support"
          );
        }
      }),
  post: (url, data, params) =>
    provider
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/login";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
};
//46 -> API
export const getProviderDetails = (params) =>
  request.get("/merago/api/provider/getProviderByUserId", params);

export const getProfileCompletion = (params) =>
  request.get("/merago/api/provider/profileCompletionStatus", params);

export const getProviderBankDetails = (params) =>
  request.get("/merago/api/provider/bankdetails/provider", params);

export const saveBankProvider = (data) =>
  request.post("/merago/api/provider/bankdetails/provider", data);

export const changePassword = (data) =>
  request.post("/merago/api/user/changePassword", data);

export const getSalutation = () =>
  request.get("/merago/api/user/provider/Salutation");

export const getLanguages = () =>
  request.get("/merago/api/provider/getLanguageslist");

export const getCountry = () => request.get("/merago/api/user/getCountries");

export const getStates = (params) =>
  request.get("/merago/api/user/getStatesByCountry", params);

export const getCities = (params) =>
  request.get("/merago/api/user/getCitiesByState", params);

export const getDoctorPersonalInfo = (params) =>
  request.get("/merago/api/provider/getDoctorPersonalInformation", params);

export const getMeragoPartnerClinics = (params) =>
  request.get("/merago/api/customer/getMeragoPartnerClinics", params);

export const getDPUrl = (params) =>
  request.post("/merago/api/storage/getDPURL", " ", params);

export const removeDPUrl = (params) =>
  request.post("/merago/api/storage/removeDP", " ", params);

export const uploadDPUrl = (data) =>
  request.post("/merago/api/storage/uploadDP", data);

//?Remove Provider Subscribed Hubs
export const removeSignUrl = (params) =>
  request.post("/merago/api/storage/removeSignature", " ", params);

//?Get Provider Subscribed Hubs
export const getSignURL = (params) =>
  request.post("/merago/api/storage/getSignatureURL", " ", params);
//?Upload Sign URL
export const uploadSignUrl = (data) =>
  request.post("/merago/api/storage/uploadSignature", data);

export const getProviderRoster = (params) =>
  request.get(
    "/merago/api/provider/IndividualProvider/ProviderRoaster",
    params
  );
export const getMyPeerMessage = (data) =>
  request.post("/merago/api/msg/getMyPeerMessages", data);

//*Get Provider Subscribed Hubs
export const getSubscribedHubs = (params) =>
  request.get(
    "/merago/api/provider/IndividualProvider/Urgentcare/Hubs",
    params
  );
//*Get Provider Payments Details
export const getProviderPayments = (params) =>
  request.get(
    "/merago/api/provider/getProviderBillingAndPaymentDetails/",
    params
  );
//*Get Provider Clinic Details
export const getClinicDetails = (params) =>
  request.get("/merago/api/provider/providerClinicListDetails/", params);
//*Get Patients List
export const getPatientsList = (params) =>
  request.get("/merago/api/provider/PatientDetails/", params);
//*Get Member details
export const getMemberDetails = (params) =>
  request.get("/merago/api/customer/getMemberByUserId/", params);
//*Get Member PCP
export const getMemberByUserIdForPcp = (params) =>
  request.get("/merago/api/customer/getMemberByUserIdForPcp/", params);
//*Get Member & Provider details
export const getMemberProviderDetails = (params) =>
  request.get(
    "/merago/api/customer/getConsultationsByMemberAndProvider/",
    params
  );
//* Get Provider Service Info
export const getProviderService = (params) =>
  request.get(
    "/merago/api/provider/IndividualProvider/ServiceInformation/",
    params
  );
//* Get Provider Service Fee Config - Normal
export const getProviderNormalServiceFeeConfig = (params) =>
  request.get("/merago/api/user/consultationByTypeConfig/", params);
//* Get Provider Service Fee Config - Urgent care
export const getProviderUCServiceFeeConfig = (params) =>
  request.get("/merago/api/user/consultationByTypeConfig/", params);
//* Service and Fee Info
export const getServiceFeeInfo = (params) =>
  request.get(
    "merago/api/provider/IndividualProvider/ServiceAndFeeInformation",
    params
  );
//* Service and Fee Info
export const getStateMedicalCouncil = () =>
  request.get("merago/api/provider/StateMedicalCouncil");
//? Service and Fee Info
export const mrnLicenseSave = (data) =>
  request.post("/merago/api/provider/LicenseDetails", data);
//? Update Doctor Personal Profile
export const updateDoctorPersonalProfile = (data) =>
  request.post("/merago/api/provider/updateDoctorPersonalProfile", data);
//? Update Doctor Personal Profile
export const getDoctorProfessionalInformation = (params) =>
  request.get("/merago/api/provider/getDoctorProfessionalInformation", params);
//* Get Specialisation
export const getListOfSelectedSpecialization = () =>
  request.get("merago/api/customer/getListOfSelectedSpecialization");
//* Get Specialisation
export const getspecialisationlist = () =>
  request.get("merago/api/customer/getspecialisationlist");
//* Get Qualifications
export const getQualificationslist = (params) =>
  request.get("merago/api/provider/getQualifications", params);

//? Update Doctor Professional Profile
export const updateDoctorProfessionalProfile = (data) =>
  request.post("/merago/api/provider/updateDoctorProfessionalProfile", data);
//? Update Doctor Service and Fee Info
export const saveServiceFeeInfo = (data) =>
  request.post(
    "/merago/api/provider/IndividualProvider/ServiceAndFeeInformation",
    data
  );
//?Send Selected Clinics
export const getClinicProviderStatus = (params) =>
  request.post("/merago/api/provider/getClinicProviderStatus", " ", params);
//?Send Provider Location
export const updateProviderLocation = (params) =>
  request.post("/merago/api/provider/updateProviderLocation", " ", params);
//? Calendar Block Calendar
export const blockCalendar = (data) =>
  request.post("/merago/api/provider/addProviderCalendarBlock", data);
//? Calendar Unblock
export const unBlockCalendar = (data) =>
  request.post("/merago/api/provider/deleteProviderCalendarBlock", data);
//?Send Provider Location
export const sendOtpToProviderOnLogin = (params) =>
  request.post("/merago/api/user/sendOtpToProviderOnLogin", " ", params);

export const getGoogleGeoCodeLatLng = (params) =>
  request.get("/merago/api/user/getGoogleMapsBylatlng", params);
export const getGoogleMapsByAddess = (params) =>
  request.get("/merago/api/user/getGoogleMapsByAddess", params);
//! 46 ->API
// //* Get Agent Details
// export const getAgentByUserId = (params) =>
//   request.get("merago/api/agent/v1/agentById", params);
