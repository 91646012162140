import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router";

const CODDataContext = createContext();

export const CODDataProvider = ({ children }) => {
  const location = useLocation();
  const [newData, setNewData] = useState([]);
  const [safData, setSafData] = useState([]);
  const [soData, setSoData] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSafWaiting, setIsSafWaiting] = useState(false);
  const [isSoNotComplete, setIsSoNotComplete] = useState(false);

  useEffect(() => {
    const waiting = newData.some(
      (item) =>
        item.requestStatus === "REQUESTED" ||
        item.requestStatus === "BACK_TO_QUEUE"
    );
    setIsWaiting(waiting);
  }, [newData]);

  useEffect(() => {
    const safWaiting = safData.some(
      (item) =>
        item.requestStatus === "REQUESTED" ||
        (item.requestStatus === "RESUBMITTED" &&
          item?.servicedByProviderId === localStorage.getItem("providerId"))
    );
    setIsSafWaiting(safWaiting);
  }, [safData]);

  useEffect(() => {
    const soNotComplete = soData.some(
      (item) => item.requestStatus !== "COMPLETED"
    );
    setIsSoNotComplete(soNotComplete);
  }, [soData]);

  const isAlertActive = useMemo(() => {
    return (
      (isWaiting || isSafWaiting || isSoNotComplete) &&
      !location.pathname.includes("/consultations") &&
      !location.pathname.includes("/consultation")
    );
  }, [isWaiting, location.pathname, isSafWaiting,isSoNotComplete]);

  useEffect(() => {
    document.title = isAlertActive
      ? "\u26A0 Alert | Merago - Provider Portal"
      : "Merago - Provider Portal";
  }, [isAlertActive]);

  return (
    <CODDataContext.Provider
      value={{
        newData,
        setNewData,
        isWaiting,
        setSafData,
        safData,
        setSoData,
        soData,
        isSafWaiting,
        isSoNotComplete,
      }}
    >
      {children}
    </CODDataContext.Provider>
  );
};

export const useCODData = () => {
  return useContext(CODDataContext);
};
