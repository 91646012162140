import axios from "axios";
import { toast } from "react-toastify";
const getAuthToken = () => {
  const auth = localStorage.getItem("agentAu")
    ? localStorage.getItem("agentAu")
    : null;
  if (auth) {
    return `Bearer ${auth}`;
  }
  return null;
};
const agent = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy": "default-src 'self'",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
});
agent.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: getAuthToken(),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    agent
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/agent";
        } else if (res?.response?.status === 500) {
          toast.error(
            "Oops something went wrong, please try again after sometime or contact Merago Support"
          );
        }
      }),
  post: (url, data, params) =>
    agent
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/agent";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence-agent";
        }
      }),
  put: (url, data, params) =>
    agent
      .put(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = "/agent";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
};
//! 21-API
//* 1.Get Agent Details
export const getAgentByUserId = (params) =>
  request.get("/merago/api/provider/agent/v1/agentById", params);
//? 2.Get list of Agent Services
export const getAgentServicesWithFilter = (data) =>
  request.post("/merago/api/provider/agent/v1/homeCareServices", data);
//* 3.Get Service Details
export const getServiceProductDetails = (params) =>
  request.get("/merago/api/provider/agent/v1/serviceDetailsById", params);
//* 4.Get Profile DP
export const getProfileDP = (params) =>
  request.post("/merago/api/storage/getDPURL", "", params);
//* 5.Get Member details
export const getMemberDetails = (params) =>
  request.get("/merago/api/customer/getMemberByUserId/", params);
//? 6.Update Service Status
export const updateAgentServiceStatus = (data) =>
  request.put("/merago/api/provider/agent/v1/updateServiceStatus", data);
//* 7.Get Service History
export const getServiceStatusHistory = (params) =>
  request.get("/merago/api/provider/agent/v1/serviceStatusHistory", params);
//? 8.Start Service By Agent
export const startServiceByAgent = (params) =>
  request.put("/merago/api/provider/agent/v1/startMemberService", "", params);
//? 9.Update Product Status
export const updateProductStatus = (data) =>
  request.put("/merago/api/provider/agent/v1/updateServiceProductStatus", data);
//* 10.Get Service Chat History
export const getServiceChatHistory = (params) =>
  request.get("/merago/api/provider/agent/v1/chats", params);
//? 11.Send Chat Reply
export const sendChatReplyAgent = (data) =>
  request.post("/merago/api/provider/agent/v1/chat/reply", data);
//* 12.GetRazorpayURL
export const getRazorpayLink = (params) =>
  request.post("/merago/api/customer/generatePaymentLink", "", params);
//* 13.Get Product History
export const getSingleProductHistory = (params) =>
  request.get(
    "/merago/api/provider/agent/v1/serviceProductStatusHistory",
    params
  );
//* 14.Get New message List
export const getNewMessageList = (params) =>
  request.get("/merago/api/provider/agent/v1/newChats", params);
//* 15.Get Profile Values
export const getProfileValuesAgent = (params) =>
  request.get("/merago/api/customer/getProfileValues", params);
//? 16.Agent Prescription
export const agentPrescription = (data) =>
  request.post("/merago/api/provider/agent/v1/savePrescription", data);
//* 17.Get Generic Medicine Name
export const getGenericMedicine = (params) =>
  request.get("/merago/api/provider/getMedicineByName", params);
//? 18.Get Product Case Notes
export const getProductCaseNotes = (params) =>
  request.post("/merago/api/document/v1/getServiceDocumentURLById", "", params);
//? 19.Reassign to self
export const updateReassignToSelf = (data) =>
  request.put("/merago/api/provider/agent/v1/assignToSelf", data);
//? 20.Get Documents by ID new
export const getDocsByIdAgent = (params) =>
  request.post("/merago/api/document/v2/getDocuementByID", "", params);
//? 21.Upload Consultation Document - Lab
export const uploadLabReport = (data, onUploadProgress) =>
  request.post("/merago/api/provider/agent/v1/uploadLabTestResults", data, {
    onUploadProgress: onUploadProgress,
  });
//? 22.Delete uploaded file
export const updateMemberScanReportStatus = (data) =>
  request.put(
    "/merago/api/provider/agent/v1/updateMemberScanReportStatus",
    "",
    data
  );
//? 23.Get list of Agent Services
export const getAgentAlertWithFilter = (data) =>
  request.post("/merago/api/provider/agent/v1/firstAlertNotifications", data);